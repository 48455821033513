import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';

// examples:
import GoogleMap from '../components/GoogleMap';

// consts: [34.0522, -118.2437]
import JAPAN_TARGET from '../const/ja_center';
import ToggleSwitch from '../components/ToggleSwitch';

// InfoWindow component
const InfoWindow = (props) => {
  const { place } = props;
  const infoWindowStyle = {
    position: 'relative',
    bottom: 150,
    left: '-45px',
    width: 220,
    backgroundColor: 'white',
    boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
    padding: 10,
    fontSize: 14,
    zIndex: 100,
  };

  return (
    <div style={infoWindowStyle}>
      <div style={{ fontSize: 16 }}>{place.name}</div>
      <div style={{ fontSize: 14 }}>
        <span style={{ color: 'grey' }}>
          日時: {place.ot}
          <br />
          M: {place.mag}
          <br />
          深さ: {place.dep}
          <br />
          最大震度: {place.maxI}
        </span>
        {/* <span style={{ color: 'orange' }}>
          {String.fromCharCode(9733).repeat(Math.floor(place.rating))}
        </span>
        <span style={{ color: 'lightgrey' }}>
          {String.fromCharCode(9733).repeat(5 - Math.floor(place.rating))}
        </span> */}
      </div>
      {/* <div style={{ fontSize: 14, color: 'grey' }}>
        {place.types[0]}
      </div>
      <div style={{ fontSize: 14, color: 'grey' }}>
        {'$'.repeat(place.price_level)}
      </div>
      <div style={{ fontSize: 14, color: 'green' }}>
        {place.opening_hours.open_now ? 'Open' : 'Closed'}
      </div> */}
    </div>
  );
};

// Marker component
const Marker = ({ show, place }) => {
  const markerStyle = {
    border: '1px solid white',
    borderRadius: '50%',
    height: show ? 15 : 5,
    width: show ? 15 : 5,
    backgroundColor: show ? 'red' : 'black',
    cursor: 'pointer',
    zIndex: 10,
  };

  return (
    <>
      <div style={markerStyle} />
      {show && <InfoWindow place={place} />}
    </>
  );
};

class MarkerInfoWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      places: [],
      isTargetMag: false,
      // isShowHeat: true,
    };
  }

  componentDidMount() {
    console.log('mount');
    console.log(this.isTargetMag);
    // fetch('places.json')
    // fetch('202310_5-5000gal.json')
    fetch(`${process.env.PUBLIC_URL}/202310_kisyou.json`)
      .then((response) => response.json())
      .then((data) => {
        data.results.forEach((result) => {
          result.show = false; // eslint-disable-line no-param-reassign
        });
        this.setState({ places: data.results });
      });
    this.setState({ isTargetMag: false });

  }

  toggleHeatTarget = () => {
    this.setState(() => ({
      isTargetMag: !this.state.isTargetMag,
    }));
  };

  // toggleShowHeat = () => {
  //   console.log("heat-toggle:" + this.state.isShowHeat);
  //   this.setState(() => {
  //     return {
  //       isShowHeat: !this.state.isShowHeat,
  //     };
  //   });
  // };

  // onChildClick callback can take two arguments: key and childProps
  onChildClickCallback = (key) => {
    this.setState((state) => {
      // console.log('key:' + key)
      // console.log('stete:' + state.place[key].name)
      const index = state.places.findIndex((e) => e.id === key);
      // console.log('index:' + index)
      state.places[index].show = !state.places[index].show; // eslint-disable-line no-param-reassign
      return {
        places: state.places,
      };
    });
  };

  render() {

    const { places } = this.state;
    const { isTargetMag } = this.state;
    // console.log('isTargetMag:' + isTargetMag);

    // let isShowHeat = this.state.isShowHeat;

    // for heatmap
    const data = places.map((place) => ({
      lat: place.lat,
      lng: place.lon,
      // weight: Math.floor(Math.random() * Math.floor(5)),
      // 重み付け? 震度 1.5倍とか?
      weight: isTargetMag
        ? parseInt(place.maxI.replace('震度', ''))
        : parseInt(place.mag),
    }));
    const heatmapData = {
      positions: data,
      options: {
        radius: 25,
        opacity: 0.7,
      },
    };

    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '0, 2rem',
          }}
        >
          <ToggleSwitch
            id="toggle-mag"
            on="M"
            off="震度"
            onClick={this.toggleHeatTarget}
          />
          heat weight target:
          {isTargetMag ? 'マグニチュード\t' : '震度\t'}
        </div>
        {/* <ToggleSwitch id="toggle-heat" onClick={this.toggleShowHeat} />
        {isShowHeat ? "true\t" : "false\t"} */}
        {!isEmpty(places) && (
          <GoogleMap
            defaultZoom={6}
            defaultCenter={JAPAN_TARGET}
            heatmap={heatmapData}
            bootstrapURLKeys={{
              key: process.env.REACT_APP_MAP_KEY,
              libraries: ['visualization'],
            }}
            onChildClick={this.onChildClickCallback}
          >
            {places.map((place) => (
              <Marker
                key={place.id}
                // lat={place.geometry.location.lat}
                // lng={place.geometry.location.lng}
                lat={place.lat}
                lng={place.lon}
                show={place.show}
                place={place}
              />
            ))}
          </GoogleMap>
        )}
      </>
    );
  }
}

InfoWindow.propTypes = {
  place: PropTypes.shape({
    name: PropTypes.string,
    // formatted_address: PropTypes.string,
    // rating: PropTypes.number,
    // types: PropTypes.arrayOf(PropTypes.string),
    // price_level: PropTypes.number,
    // opening_hours: PropTypes.shape({
    //   open_now: PropTypes.bool,
    // }),
  }).isRequired,
};

Marker.propTypes = {
  show: PropTypes.bool.isRequired,
  place: PropTypes.shape({
    name: PropTypes.string,
    // formatted_address: PropTypes.string,
    // rating: PropTypes.number,
    // types: PropTypes.arrayOf(PropTypes.string),
    // price_level: PropTypes.number,
    // opening_hours: PropTypes.shape({
    //   open_now: PropTypes.bool,
    // }),
  }).isRequired,
};

export default MarkerInfoWindow;
