import React from 'react';
import './App.css';
import MarkerInfoWindow from './examples/MarkerInfoWindow';

function App() {
  return (
    <div style={{ height: '100vh', width: '100vw' }}>
      <MarkerInfoWindow />
    </div>
  );
}

export default App;
