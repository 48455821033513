import React from 'react';
import styled from 'styled-components';
import { Label, Input } from 'reactstrap';

const ToggleSwitchLabel = styled(Label)`
  cursor: pointer;
  position: relative;
  display: inline-block;
  margin: 10px 0;
  width: 80px;
  height: 34px;
  background-color: gray;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  border-radius: 25px;

  &::before {
    content: "";
    position: absolute;
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: #fff;
    background-color: white;
    -webkit-transition: 0.6s;
    transition: 0.6s;
    border-radius: 25px;
  }

  &::after {
    display: block;
    content: attr(data-off);
    position: absolute;
    top: 4px;
    right: 12px;
    color: #fff;
  }
`;

const ToggleSwitchInput = styled(Input)`
  display: none;
  opacity: 0;

  &:checked + ${ToggleSwitchLabel} {
    // background-color: #41d641;
  }
  &:checked + ${ToggleSwitchLabel}:before {
    -webkit-transform: translateX(46px);
    -ms-transform: translateX(46px);
    transform: translateX(46px);
  }
  &:checked + ${ToggleSwitchLabel}:after {
    display: block;
    content: attr(data-on);
    position: absolute;
    top: 4px;
    left: 12px;
    color: #fff;
  }
`;

const ToggleSwitch = ({
  id, on = 'ON', off = 'OFF', onClick,
}) => (
  <>
    <ToggleSwitchInput type="checkbox" id={id} onClick={onClick} />
    <ToggleSwitchLabel for={id} data-on={on} data-off={off} />
  </>
);

export default ToggleSwitch;
